<!--
 * @Author: wxy
 * @Date: 2022-09-26 10:13:51
 * @LastEditTime: 2022-09-26 10:52:17
 * @Description: 已接受工单-列表
-->
<template>
  <div>已接受工单列表</div>
</template>

<script>
export default {};
</script>

<style>

</style>